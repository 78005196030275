<template>
    <v-card class="mx-auto mb-5 pb-3">

        <v-list tile>
            <v-subheader class="primary--text">INFO</v-subheader>
            <!-- <v-list-item-group color="primary"> -->
                <v-list-item class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Totale eventi: <strong class="text-capitalize primary--text">{{ element.events.length }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.status" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Eventi iniziati: <strong class="text-capitalize" :style="{color: constants.Inizio}">{{ inizio }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.status" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Eventi in svolgimento: <strong class="text-capitalize" :style="{color: constants.Svolgimento}">{{ svolgimento }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.status" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Eventi finiti: <strong class="text-capitalize" :style="{color: constants.Fine}">{{ fine }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.status" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Eventi annullati: <strong class="text-capitalize" :style="{color: constants.Annullamento}">{{ annullamento }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.done" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Eventi checkati: <strong class="text-capitalize primary--text">{{ checked }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.done" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Eventi non checkati: <strong class="text-capitalize primary--text">{{ notChecked }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="element.total" class="min-height-auto">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Tipo: <strong class="primary--text text-capitalize">{{ element.typeTotal }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="min-height-auto" v-if="element.typeTotal === 'somma' && element.total">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Totale: <strong class="primary--text text-capitalize">{{ total }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="min-height-auto" v-if="element.typeTotal === 'budget' && element.total">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Totale budget: <strong class="primary--text text-capitalize">{{ element.budget }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="min-height-auto" v-if="element.typeTotal === 'budget' && element.total">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Totale speso: <strong class="primary--text text-capitalize">{{ total }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="min-height-auto" v-if="element.typeTotal === 'budget' && element.total">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>Budget rimanente: <strong class="primary--text text-capitalize">{{ parseFloat(element.budget) - parseFloat(total) }}</strong></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            <!-- </v-list-item-group> -->
        </v-list>
    </v-card>
</template>

<script>
import constants from '../utils/constants'
export default {
    name: "CardDetail",
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            constants
        }
    },
    computed: {
        total() {
            if(!this.element.total) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                total += parseFloat(event.total)
            });
            return total
        },
        inizio() {
            if(!this.element.status) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                if(event.status === 'Inizio') {
                    total += 1
                }
            });
            return total
        },
        svolgimento() {
            if(!this.element.status) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                if(event.status === 'Svolgimento') {
                    total += 1
                }
            });
            return total
        },
        fine() {
            if(!this.element.status) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                if(event.status === 'Fine') {
                    total += 1
                }
            });
            return total
        },
        annullamento() {
            if(!this.element.status) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                if(event.status === 'Annullamento') {
                    total += 1
                }
            });
            return total
        },
        checked() {
            if(!this.element.done) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                if(event.done) {
                    total += 1
                }
            });
            return total
        },
        notChecked() {
            if(!this.element.done) {
                return 0
            }
            var total = 0
            this.element.events.forEach(event => {
                if(!event.done) {
                    total += 1
                }
            });
            return total
        },
    }
}
</script>