<template>
    <v-card
    class="mx-auto my-2"
  >

    <v-card-title>
      {{ event.title }}
      <v-spacer></v-spacer>
      <v-switch
        v-if="element.done"
        :input-value="event.done"
        @change="changeEvent"
      ></v-switch>
    </v-card-title>

    <v-card-subtitle v-if="element.status" class="py-3">
     Stato: <strong :style="{color: constants[event.status]}">{{ event.status }}</strong>
    </v-card-subtitle>

    <v-card-subtitle v-if="element.total" class="py-3">
     Totale: <strong class="primary--text">{{ event.total }}</strong>
    </v-card-subtitle>

    <v-card-text class="text--primary" v-if="!utils.checkIsUndefinedOrEmpty(event.description)">
      <div>{{ event.description }}</div>
    </v-card-text>
    <!-- <v-card-text class="text--primary">
      <div>{{ event.description }}</div>
    </v-card-text> -->

    <v-card-actions>
      <v-btn
        color="secondary"
        text
        @click="editEvent"
      >
        Modifica
      </v-btn>

      <v-btn
        color="error"
        text
        @click="deleteEvent"
      >
        Elimina
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import swal from '../utils/swal'
import { mapGetters } from 'vuex'
import constants from '../utils/constants'
import utils from '../utils/utils'
export default {
    name: "EventCard",
    props: {
        event: Object
    },
    data() {
      return {
        constants,
        utils
      }
    },
    methods: {
      changeEvent(done){
        this.$store.commit("editEvent", utils.newEvent(this.event.title, this.event.description, this.event.status, done, this.event.total, this.event.id))
      },
      editEvent() {
        this.$emit("edit", this.event)
          // this.$refs.eventBottomSheet.sheet = true
        // this.$router.push({name: "DetailEvent", params: {id: this.element.id}})
      },
      deleteEvent() {
        swal.deleteElementSwal(() => {
          this.$store.commit("deleteEvent", this.event)
        })
      }
    },
    computed: {
        ...mapGetters(["element"])
    }
}
</script>