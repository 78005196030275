<template>
  <div class="pa-5">
    <div v-if="!utils.checkIsUndefined(element)" class="d-flex justify-space-between align-center">
      <h1>{{ element.title }}</h1>
      <v-btn icon color="primary" @click="edit">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </div>
    <div v-if="!utils.checkIsUndefined(element)" class="ma-0">
      <v-alert
        border="top"
        class="mt-4 w-100"
        colored-border
        type="info"
        elevation="2"
        v-if="element.events.length === 0"
      >
        Nessun evento per questa tab, creane uno nuovo attraverso il bottone centrale in basso.
      </v-alert>
      <div v-else>
        <card-detail class="mt-5" :element="element"/>
        <v-row>
          <v-col class="pt-0" cols="12" md="3" sm="6" v-for="event in element.events" :key="event.id">
            <event-card :event="event" @edit="editEvent"/>
          </v-col>
        </v-row>
      </div>
    </div>
    <event-bottom-sheet ref="eventBottomSheet"/>
    <element-bottom-sheet ref="elementBottomSheet" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import utils from '../utils/utils'
import EventCard from '../components/EventCard.vue'
import EventBottomSheet from "../components/EventBottomSheet.vue"
import ElementBottomSheet from "../components/ElementBottomSheet.vue"
import CardDetail from '../components/CardDetail.vue'

export default {
  name: "DetailTab",
  data() {
    return {
      id: null,
      element: null,
      utils
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.setData()
  },
  methods: {
    setData() {
      if(utils.checkIsUndefined(this.categories)) {
        return
      }
      if(utils.checkIsUndefined(this.tabIndex)) {
        return
      }
      var tabIndex = utils.checkIsUndefinedOrEmpty(this.tabIndex) ? 0 : this.tabIndex
      const index = this.categories[tabIndex].elements.findIndex(object => {
        return object.id === this.id;
      });
      this.element = this.categories[tabIndex].elements[index]
      this.$store.commit("setTab", this.element)
    },
    edit() {
      this.$refs.elementBottomSheet.sheet = true
      this.$refs.elementBottomSheet.isTab = true
      this.$refs.elementBottomSheet.element = this.element
    },
    editEvent(event) {
      // this.$emit("edit", this.event)
      this.$refs.eventBottomSheet.sheet = true
      this.$refs.eventBottomSheet.event = event
      // this.$router.push({name: "DetailEvent", params: {id: this.element.id}})
    },
  },
  computed: {
    ...mapGetters(["categories", "tabIndex"])
  },
  watch: {
    categories: {
      deep: true,
      handler: function() {
        this.setData()
      }
    },
    tabIndex: {
      deep: true,
      handler: function() {
        this.setData()
      }
    }
  },
  components: {
    EventCard,
    EventBottomSheet,
    ElementBottomSheet,
    CardDetail
  }
}
</script>
